<template>
    <div>
      <p v-if="loading">Sie werden automatisch weitergeleitet...</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'Support',
    data() {
      return {
        loading: true
      };
    },
    mounted() {
      this.getToken();
    },
    methods: {
      async getToken() {
       
            axios.get(`api/v1/zendesk/get_jwt`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
            })
            .then((response) => {
                // Redirect to the Zendesk SSO page with the received token
                window.location.href = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
      
      }
    }
  };
  </script>
