<template>
	<div class="main">
        <div class="logo"></div>
        <div class="login-box">
            <div class="head">
                <div class="subtitle">Bestandskunden</div>
                <div class="title">Aktivierung</div>
            </div>
            <transition mode="out-in" name="fade3">
                <Notification v-if="error" status="error" style="margin-top:20px"></Notification>
            </transition>
            <transition mode="out-in" name="fade2">
                <div class="whitecontent" v-if="loading" key="loading">
                    <div class="loading"><div class="loader"></div></div>
                </div>
                <div class="whitecontent" v-else>
                    <FormulateInput type="password" v-model="password" label="Passwort" validation="bail|required|max:40|min:8" />
                    <FormulateInput type="password" v-model="repeatPassword" label="Passwort wiederholen" validation="bail|required|max:40|min:8" />
                    <div class="az-button" @click="activate()">
                        Zugang aktivieren
                    </div>
                </div>
            </transition>
            <div class="help-bar">
                <div class="help-bar-left">Hilfe benötigt?</div>
                <div class="help-bar-right">
                    <a class="support-button" href="mailto:support@bekumoo.de">
                        <i class="far fa-life-ring"></i> Support
                    </a>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Notification from './Notification'

export default {
	name: 'Activate',
    data() {
        return {
            loading: false,
            password: '',
            repeatPassword: '',
            error: false
        }
    },
    components: {
        Notification
    },
	methods: {
        activate() {
            if ((this.password.length >= 8) && (this.password == this.repeatPassword)) {
                this.loading = true
                setTimeout(function() {
                    this.$http.post(
                        '/api/v1/user/activate',
                        {
                            token: this.$route.params.token,
                            password: this.password
                        }
                    ).then(() => {
                        this.$router.push({ name: 'login' })
                    }).catch(() => {
                        this.loading = false
                        this.notificate()
                    })
                }.bind(this), 1000)
            } else {
                this.notificate()
            }
        },
		notificate() {
			this.error = true
			setTimeout(function() {
				this.error = null
			}.bind(this), 3000)
		},
    }
}
</script>

<style scoped>
    .main {
        background-color: #F8FBFD;
    }
    .logo {
        position: absolute;
        box-sizing: border-box;
        width: 276px;
        height: 28px;
        line-height: 28px;
        top: 40px;
        padding-left: 40px;
        font-weight: 700;
        font-size: 22px;
        color: #1B214250;
        background-image: url('/static/logo-login.svg');
        background-repeat: no-repeat;
        background-position: 42px center;
    }
    .login-box {
        width: 380px;
        height: 450px;
        margin-left: auto;
        margin-right: auto;
        margin-top: calc((100vh - 450px) / 2);
    }
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
	}
	.subcontent {
		width: calc(50% - 15px);
		margin-top: 50px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.whitecontent {
		margin-top: 35px;
		width: auto;
		height: auto;
		border-radius: 4px;
		background-color: #FFFFFF;
        box-sizing: border-box;
        padding: 32px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
	}
    .az-button {
        width: auto;
        height: 46px;
        background-color: #1B2142;
        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        border-radius: 4px;
        cursor: pointer;
        margin-top: 35px;
    }
    .az-button:hover {
        background-color: #20264b;
    }
    .az-button i {
        font-size: 20px;
        margin-right: 20px;
        color: #FFFFFF50;
    }
    .button-info {
        width: auto;
        height: 20px;
        line-height: 20px;
        margin-top: 25px;
        font-size: 16px;
        color: #1B214250;
    }
    .new-customer {
        width: auto;
        height: 50px;
        line-height: 50px;
        border: 1px solid #D5E3EC;
        border-radius: 4px;
        text-align: center;
        margin-top: 35px;
        font-size: 15px;
        font-weight: 600;
    }
    .new-customer:hover {
        background-color: #FFFFFF50;
        cursor: pointer;
    }
    .help-bar {
        width: auto;
        height: 32px;
        line-height: 32px;
        margin-top: 35px;
    }
    .help-bar-left {
        width: 50%;
        height: 32px;
        float: left;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
        font-size: 15px;
        color: #1B214275;
    }
    .help-bar-right {
        width: 50%;
        height: 32px;
        float: right;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .support-button {
        display: inline-block;
        height: 32px;
        line-height: 32px;
        background-color: #FF8A00;
        border-radius: 4px;
        padding-left: 14px;
        padding-right: 14px;
        color: white;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
    }
    .support-button i {
        margin-right: 5px;
    }
	.loader {
		border: 3px solid transparent; /* Light grey */
		border-top: 3px solid #1B2142; /* Blue */
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
		margin-bottom: 80px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	.fade2-enter-active, .fade2-leave-active {
		transition: opacity .5s;
	}
	.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
	.fade3-enter-active, .fade3-leave-active {
		transition: opacity .5s;
	}
	.fade3-enter, .fade3-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
