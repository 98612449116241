<template>
	<div class="main">
		<div class="right" :style="{width: 'calc(100% - ' + sidebarWidth + 'px)'}">
			<MainNavigation :width="{width: 'calc(100% - ' + sidebarWidth + 'px)'}" :user="user"></MainNavigation>
			<div class="content content-padding">
				<div class="head">
					<div class="subtitle">Settings</div>
					<div class="title">Profil</div>
				</div>
				<transition mode="out-in" name="fade3">
					<Notification v-if="notificationStatus == 'success'" status="success"></Notification>
					<Notification v-if="notificationStatus == 'error'" status="error"></Notification>
				</transition>
				<div class="whitecontent">
					<div class="whitecontent-title">
						Passwort ändern
					</div>
					<div class="whitecontent-main">
						<div class="field">
							<div class="label">Aktuelles Passwort</div>
							<FormulateInput
								v-model="currentPassword"
								type="password"
								placeholder=""
							/>
							<div class="label">Neues Passwort</div>
							<FormulateInput
								v-model="newPassword"
								type="password"
								placeholder=""
								validation="bail|required|max:40|min:8" 
							/>
							<div class="label">Neues Passwort wiederholen</div>
							<FormulateInput
								v-model="newPasswordRepeat"
								type="password"
								placeholder=""
								validation="bail|required|max:40|min:8" 
							/>
						</div>
						<div class="field">
							<div class="buttons">
								<transition mode="out-in" name="fade2">
									<div class="button-save disabled" v-if="(currentPassword.length < 1) || (newPassword.length < 8) || (newPasswordRepeat.length < 8) || (newPassword != newPasswordRepeat)" key="disabledSaveButton"><i class="far fa-save"></i> Speichern</div>
									<div class="button-save" @click="editPassword()" v-else-if="!saving" key="activeSaveButton"><i class="far fa-save"></i> Speichern</div>
									<div class="button-save saving" v-else key="savingSaveButton"><div class="button-loader"></div></div>
								</transition>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainNavigation from './MainNavigation'
import Notification from './Notification'

export default {
	name: 'SettingsProfile',
	data() {
		return {
			currentPassword: '',
			newPassword: '',
			newPasswordRepeat: '',
			notificationStatus: null,
			saving: false
		}
	},
	components: {
		MainNavigation,
		Notification
	},
	props: [
		'sidebar',
		'user'
	],
	methods: {
        editPassword() {
            if ((this.newPassword.length >= 8) && (this.newPassword == this.newPasswordRepeat)) {
                this.saving = true
                setTimeout(function() {
                    this.$http.post(
                        '/api/v1/user/password/edit',
                        {
							password: this.newPassword,
							currentPassword: this.currentPassword
						},
						{ headers: {
							Authorization: 'Bearer ' + localStorage.getItem('jwt')
						}}
                    ).then(() => {
						this.saving = false
						this.currentPassword = ''
						this.newPassword = ''
						this.newPasswordRepeat = ''
						this.notificate('success')
                    }).catch(() => {
                        this.saving = false
                        this.notificate('error')
                    })
                }.bind(this), 1000)
            } else {
                this.notificate('error')
            }
        },
		notificate(status) {
			this.notificationStatus = status
			setTimeout(function() {
				this.notificationStatus = null
			}.bind(this), 3000)
		},
	},
	computed: {
		sidebarWidth() {
			if (this.sidebar) {
				return 276
			}
			return 0
		}
	}
}
</script>

<style scoped>
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
	}
	.subcontent {
		width: calc(50% - 15px);
		margin-top: 50px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
	}
	.whitecontent-title {
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: 12px;
		border-bottom: 1px solid #dfe9f0;
		padding-left: 20px;
	}
	.whitecontent-main {
		padding: 20px;
	}
	.label {
		font-weight: 600;
		font-size: 12px;
		margin-bottom: 5px;
	}
	.buttons {
		overflow: auto;
		margin-top: 40px;
		margin-bottom: 10px;
	}
	.button-save {
		height: 36px;
		line-height: 36px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		background-color: #1B2142;
		color: white;
		font-size: 13px;
		font-weight: 600;
		float: right;
		cursor: pointer;
	}
	.button-save.disabled {
		cursor: default;
		background-color: #b3b8d4;
	}
	.button-save i {
		margin-right: 6px;
	}
	.button-save:not(.disabled):hover {
		background-color: #262c52;
	}
	.button-loader {
		border: 2px solid transparent;
		border-top: 2px solid #ffffff;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 11px;
		margin-bottom: 11px;
		margin-left: 20px;
		margin-right: 20px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	.fade3-enter-active, .fade3-leave-active {
		transition: opacity .5s;
	}
	.fade3-enter, .fade3-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
