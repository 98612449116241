<template>
	<div class="main">
		<div class="right" :style="{width: 'calc(100% - ' + sidebarWidth + 'px)'}">
			<MainNavigation :width="{width: 'calc(100% - ' + sidebarWidth + 'px)'}" :user="user"></MainNavigation>
			<div class="content content-padding">
				<div class="head">
					<div class="subtitle">{{ $route.meta.subtitle }}</div>
					<div class="title">Übersicht</div>
				</div>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import MainNavigation from './MainNavigation'

export default {
	name: 'Vertrieb',
	components: {
		MainNavigation
	},
	props: [
		'sidebar',
		'user'
	],
	computed: {
		sidebarWidth() {
			if (this.sidebar) {
				return 276
			}
			return 0
		}
	}
}
</script>

<style scoped>
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
		overflow: auto;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
	}
	.subcontent {
		width: calc(50% - 15px);
		padding-top: 25px;
		margin-bottom: 25px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.full {
		width: auto;
		clear: both;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: 300px;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
	}
</style>
