<template>
	<div id="app">
		<transition mode="out-in" name="fade">
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
		}
	}
}
</script>

<style lang="scss">
@import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
.formulate-input, .formulate-input input {
	font-family: 'Inter', sans-serif !important;
	font-size: 13px;
}
.formulate-input-element, .formulate-input[data-classification='select'] select {
	width: 100% !important;
	display: inline;
	font-family: 'Inter', sans-serif !important;
	font-size: 13px;
}
.formulate-input[data-classification='select'] select, .formulate-input input, .formulate-input[data-classification="textarea"] textarea {
	border: 1px solid #dfe9f0;
	font-family: 'Inter', sans-serif !important;
	font-size: 13px;
}
.formulate-input-label {
	font-size: 13px !important;
	font-weight: 600 !important;
	padding-bottom: 3px;
	font-family: 'Inter', sans-serif !important;
}
body, html {
	margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif !important;
	background-color: #F8FBFD;
}
#app {
	color: #1B2142;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
