<template>
    <div class="subcontent full">
        <EmbedDashboard :height="680" :name="'cxbox-overview'" :category="'service'"></EmbedDashboard>
    </div>
</template>

<script>
import EmbedDashboard from './EmbedDashboard'

export default {
	name: 'CXBox',
	components: {
		EmbedDashboard
	}
}
</script>

<style scoped>
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
		overflow: auto;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
	}
	.subcontent {
		width: calc(50% - 15px);
		padding-top: 25px;
		margin-bottom: 25px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.full {
		width: auto;
		clear: both;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: 300px;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
	}
</style>
