<template>
	<div id="app">
		<SideNavigation :show="sidebar" v-on:hide="hideSidebar" v-on:show="showSidebar" :user="user"></SideNavigation>
		<router-view :sidebar="sidebar" :user="user"></router-view>
	</div>
</template>

<script>
import SideNavigation from './SideNavigation'
export default {
	name: 'App',
	data() {
		return {
			sidebar: true,
			user: null
		}
	},
	methods: {
		hideSidebar() {
			this.sidebar = false
		},
		showSidebar() {
			this.sidebar = true
		},
        loadUser() {
            this.$http.get('/api/v1/user/details',
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
            ).then((response) => {
				this.user = response.data
            }).catch(() => {
                // call token invalidation
                localStorage.removeItem('jwt')
                this.$router.push({ name: 'login' })
            })
        }
    },
    mounted() {
        this.loadUser()
    },
	components: {
		SideNavigation
	}
}
</script>

<style>
body, html {
	margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif;
	background-color: #F8FBFD;
}
.content-padding {
	margin-top: 56px;
}
#app {
	color: #1B2142;
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
