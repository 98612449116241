<template>
	<div class="main">
		<div class="right" :style="{width: 'calc(100% - ' + sidebarWidth + 'px)'}">
			<MainNavigation :width="{width: 'calc(100% - ' + sidebarWidth + 'px)'}" :user="user"></MainNavigation>
			<div class="content content-padding">
				<div class="head">
					<div class="subtitle">Ereignisse</div>
					<transition mode="out-in" name="fade">
						<div class="title" :key="$route.params.type">{{ transformTitle($route.params.type) }}</div>
					</transition>
				</div>
				<div class="subcontent full">
					<div class="summary-bar" v-show="false">
						<div class="summary">
							<i class="fas fa-circle" style="color:rgb(155, 175, 201)"></i> Gesamt 5
						</div>
						<div class="summary">
							<i class="far fa-circle" style="color:rgb(231, 186, 38)"></i> Neu 1
						</div>
						<div class="summary">
							<i class="far fa-dot-circle" style="color: rgb(16, 92, 190)"></i> In Bearbeitung 1
						</div>
						<div class="summary">
							<i class="fas fa-exclamation-circle" style="color:rgb(218, 57, 8)"></i> Überfällig 1
						</div>
						<div class="summary">
							<i class="fas fa-check-circle" style="color:rgb(146, 187, 33)"></i> Geschlossen 1
						</div>
					</div>
					<transition mode="out-in" name="fade2">
						<div class="whitecontent" v-if="loading" key="loading">
							<div class="loading"><div class="loader"></div></div>
						</div>
						<div class="whitecontent" v-else key="loaded">
							<table class="table">
								<tr class="table-head">
									<th class="table-head-column" v-for="col in cols" :key="col">{{ col }}</th>
								</tr>
								<router-link class="table-row" tag="tr" v-for="row in tracking" :to="{ name: 'tracking-detail', params: { id: row.id }}" :key="row.id">
									<td class="table-row-column">#{{ row.id }}</td>
									<td class="table-row-column">{{ row.name }}</td>
									<td class="table-row-column">{{ row.department }}</td>
									<td class="table-row-column">{{ row.kpi }}</td>

									<td class="table-row-column" v-if="row.unit == '€' || row.unit == 'h'">{{ ((row.current_value).toFixed(1)).replace(/\./g, ',')  }}{{ row.unit }}</td>
									<td class="table-row-column" v-else>{{ ((row.current_value*100).toFixed(1)).replace(/\./g, ',')  }}{{ row.unit }}</td>

									<td class="table-row-column" v-if="row.unit == '€' || row.unit == 'h'">{{ ((row.target_value).toFixed(1)).replace(/\./g, ',') }}{{ row.unit }}</td>
									<td class="table-row-column" v-else>{{ ((row.target_value*100).toFixed(1)).replace(/\./g, ',') }}{{ row.unit }}</td>

									<td class="table-row-column" v-if="row.unit == '€' || row.unit == 'h'">{{ ((row.delta).toFixed(1)).replace(/\./g, ',') }}{{ row.unit }}</td>
									<td class="table-row-column" v-else>{{ ((row.delta*100).toFixed(1)).replace(/\./g, ',') }}{{ row.unit }}</td>

									<td class="table-row-column">{{ ((100/(row.target_value/row.current_value)).toFixed(1)).replace(/\./g, ',') }}%</td>
									<td class="table-row-column">{{ row.status }}</td>
									<td class="table-row-column">{{ row.created_at }}</td>
									<td class="table-row-column">{{ row.deadline_start }}</td>
								</router-link>
							</table>
							<div class="pagination-bar">
								<div class="pagination">
										<router-link
											v-if="$route.params.page > 1"
											class="pagination-prev"
											tag="div"
											:to="{ name: 'tracking-overview', params: {
												page: $route.params.page - 1,
												type: $route.params.type
											}}"
										>
											<i class="fas fa-angle-left"></i>
										</router-link>
										<div v-else class="pagination-prev active">
											<i class="fas fa-angle-left"></i>
										</div>
										<router-link
											class="pagination-pos"
											tag="div"
											v-for="page in pages"
											:to="{ name: 'tracking-overview', params: {
												page: page,
												type: $route.params.type
											}}"
											:key="page"
											:class="{ 'active': page == $route.params.page }"
										>
											{{ page }}
										</router-link>
										<router-link
											v-if="$route.params.page < pages"
											class="pagination-next"
											tag="div"
											:to="{ name: 'tracking-overview', params: {
												page: $route.params.page + 1,
												type: $route.params.type
											}}"
										>
											<i class="fas fa-angle-right"></i>
										</router-link>
										<div v-else class="pagination-next active">
											<i class="fas fa-angle-right"></i>
										</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainNavigation from './MainNavigation'

export default {
	name: 'Tracking',
	components: {
		MainNavigation
	},
	data() {
		return {
			cols: ['ID', 'Name', 'Betrieb', 'KPI', 'Ist', 'Soll', 'Δ', 'Zielerreichung', 'Status', 'Erstellungsdatum', 'Frist Bearbeitung'],
			tracking: [],
			pages: null,
			loading: true
		}
	},
	props: [
		'sidebar',
		'user'
	],
	methods: {
		transformTitle(title) {
			return title.replace('ue', 'ü').replace('ae', 'ä').replace('-', ' ')
		},
		loadTracking() {
			this.loading = true
			this.$http.get(
				'/api/v1/tracking/overview?status=' + this.$route.params.type + '&page=' + this.$route.params.page,
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
				).then((response) => {
				this.tracking = response.data.items
				this.pages = response.data.pages
				this.loading = false
			})
		},
		prevPage() {
			if (this.$route.params.page > 1) {
				return this.$route.params.page - 1
			} else {
				return 1
			}
		}
	},
	computed: {
		sidebarWidth() {
			if (this.sidebar) { return 276 }
			return 0
		}
	},
	mounted() {
		this.loadTracking()
	},
	watch: {
		'$route'() {
			this.loadTracking()
		}
	}
}
</script>

<style scoped>
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
		overflow: auto;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
		text-transform: capitalize;
	}
	.subcontent {
		width: calc(50% - 15px);
		padding-top: 25px;
		margin-bottom: 25px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.full {
		width: auto;
		clear: both;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: auto;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		overflow:hidden;
	}
	.table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
		color: #1B2142;
	}
	.table-head {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: 12px;
		border-bottom: 2px solid #dfe9f0;
	}
	.table-head-column {
		padding-left: 20px;
		padding-right: 15px;
		box-sizing: border-box;
	}
	.table-row {
		width: auto;
		height: 50px;
		line-height: 50px;
		font-size: 13px;
		border-bottom: 1px solid #dfe9f0;
		cursor: pointer;
	}
	.table-row:hover {
		background-color: #dfe9f030;
	}
	.table-row-column {
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 15px;
	}
	.summary-bar {
		width: auto;
		height: 60px;
	}
	.summary {
		width: auto;
		height: 50px;
		line-height: 50px;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 20px;
		float: left;
		border-radius: 4px;
		background-color: #FFFFFF;
		font-size: 13px;
		font-weight: 600;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		cursor: pointer;
	}
	.summary i {
		margin-right: 7px;
		color: rgb(218, 57, 8);
	}
	.summary.active {
		background-color: #1B2142;
		color: white;
	}
	.pagination-bar {
		width: auto;
		height: 54px;
		padding: 13px;
		box-sizing: border-box;
		font-size: 11px;
	}
	.pagination {
		width: auto;
		height: 28px;
		line-height: 28px;
		text-align: center;
		background-color: #FFFFFF;
        border: 1px solid #dfe9f0;
		display: inline-block;
		border-radius: 4px;
	}
	.pagination > div:hover {
		background-color: #f8f9fa;
		cursor: pointer;
	}
	.pagination-prev {
		width: 27px;
		height: 28px;
		float: left;
		box-sizing: border-box;
	}
	.pagination-pos {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.pagination-pos.active {
		background-color: #f8f9fa;
		color: #1B214270;
	}
	.pagination-next {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.pagination-prev.active, .pagination-next.active {
		background-color: #f8f9fa;
		color: #1B214270;
		cursor: default;
	}
	.loader {
		border: 3px solid transparent; /* Light grey */
		border-top: 3px solid #1B2142; /* Blue */
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 150px;
		margin-bottom: 150px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	.fade2-enter-active, .fade2-leave-active {
		transition: opacity .5s;
	}
	.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
