<template>
	<div class="main">
		<div class="right" :style="{width: 'calc(100% - ' + sidebarWidth + 'px)'}">
			<MainNavigation :width="{width: 'calc(100% - ' + sidebarWidth + 'px)'}" :user="user"></MainNavigation>
			<div class="content content-padding">
				<div class="head">
					<div class="subtitle">Maßnahmen</div>
					<transition mode="out-in" name="fade">
						<div class="title">Detail</div>
					</transition>
				</div>
				<div class="subcontent full">
					<transition mode="out-in" name="fade2">
						<Notification v-if="notificationStatus == 'success'" status="success"></Notification>
						<Notification v-if="notificationStatus == 'error'" status="error"></Notification>
					</transition>
					<transition mode="out-in" name="fade2">
						<div class="whitecontent" v-if="(loadingDetails && loadingReasons && loadingComments) || !details" key="loading">
							<div class="loading"><div class="loader"></div></div>
						</div>
						<div class="whitecontent" v-else key="loaded" style="padding:40px">
							<div class="detail-title">Maßnahme #{{ $route.params.id }}</div>
							<div class="detail-left">
								<div class="field">
									<div class="label">Kennzahl</div>
									<div class="value">{{ details.kpi }}</div>
								</div>
								<div class="field">
									<div class="label">Betrieb</div>
									<div class="value">{{ details.department }}</div>
								</div>
								<div class="field">
									<div class="label">Status</div>
									<div class="value">{{ details.status }}</div>
								</div>
								<div class="field">
									<div class="label">Erstellungsdatum</div>
									<div class="value">{{ details.created_at }}</div>
								</div>
								<div class="field">
									<div class="label">Frist Bearbeitung</div>
									<div class="value">{{ details.deadline_start }}</div>
								</div>
								<div class="field">
									<div class="label">Frist Umsetzung</div>
									<div class="value">{{ details.deadline_end }}</div>
								</div>

								<div class="detail-subtitle">KPI Werte</div>

								<div class="field" v-if="details.unit == '€' || details.unit == 'h'">
									<div class="label">Soll Wert</div>
									<div class="value">{{ ((details.target_value).toFixed(1)).replace(/\./g, ',') }}{{ details.unit }}</div>
								</div>
								<div class="field" v-else>
									<div class="label">Soll Wert</div>
									<div class="value">{{ ((details.target_value*100).toFixed(1)).replace(/\./g, ',') }}{{ details.unit }}</div>
								</div>

								<div class="field" v-if="details.unit == '€' || details.unit == 'h'">
									<div class="label">Ist Wert (Ø 7 Tage)</div>
									<div class="value">{{ ((details.current_value).toFixed(1)).replace(/\./g, ',') }}{{ details.unit }}</div>
								</div>
								<div class="field" v-else>
									<div class="label">Ist Wert (Ø 7 Tage)</div>
									<div class="value">{{ ((details.current_value*100).toFixed(1)).replace(/\./g, ',') }}{{ details.unit }}</div>
								</div>

								<div class="field" v-if="details.unit == '€' || details.unit == 'h'">
									<div class="label">Δ</div>
									<div class="value">{{ ((details.delta).toFixed(1)).replace(/\./g, ',') }}{{ details.unit }}</div>
								</div>
								<div class="field" v-else>
									<div class="label">Δ</div>
									<div class="value">{{ ((details.delta*100).toFixed(1)).replace(/\./g, ',') }}{{ details.unit }}</div>
								</div>

								<div class="field">
									<div class="label">Zielerreichung</div>
									<div class="value">{{ ((100/(details.target_value/details.current_value)).toFixed(1)).replace(/\./g, ',') }}%</div>
								</div>
								
								<div class="field">
									<div class="label">Cluster</div>
									<div class="value">Sonstiges</div>
								</div>

								<div class="detail-subtitle">Verantwortlicher</div>

								<div class="field">
									<div class="label">{{ details.responsible.name }}</div>
									<div class="value">{{ details.responsible.role }}</div>
								</div>

							</div>
							<div class="detail-right">
								<div class="reasons-area">
									<h4>Ursachen</h4>
									<div class="field">
										<FormulateInput
											v-model="selectedReason"
											:options="reasonOptions"
											type="select"
											placeholder="Ursache auswählen"
											label="Auswahl Ursache"
										/>
									</div>
									<div class="selected-subreasons-label">Auswahl Unterkategorien</div>
									<div class="selected-subreasons">
										<div class="selected-subreason" v-for="sr in selectedSubReasons" :key="sr.label">
											<div class="selected-subreason-value">{{ sr.label }}</div>
											<div class="selected-subreason-close" @click="removeSelectedSubreason(sr)"><i class="fas fa-times"></i></div>
										</div>
									</div>
									<div class="field" v-if="subReasonOptions">
										<FormulateInput
											v-model="selectedSubReason"
											:options="subReasonOptions"
											type="select"
											placeholder="Unterkategorie auswählen"
											label=""
										/>
									</div>
									<div class="field" style="border-bottom:0px" v-else>
										<div class="no-comments">Bitte Oberkategorie auswählen</div>
									</div>
									<div class="field">
										<div class="buttons">
											<transition mode="out-in" name="fade2">
												<div class="button-save disabled" v-if="!selectedReason || (selectedSubReasons.length < 1)" key="disabledSaveButton"><i class="far fa-save"></i> Speichern</div>
												<div class="button-save" @click="saveAction()" v-else-if="!saving" key="activeSaveButton"><i class="far fa-save"></i> Speichern</div>
												<div class="button-save saving" v-else key="savingSaveButton"><div class="button-loader"></div></div>
											</transition>
										</div>
									</div>
									<div class="field" v-if="false">
										<div class="selection-field">
											Unterkategorie auswählen
										</div>
										<div class="selection-dropdown">
											<div class="selection-dropdown-item" v-for="x in subReasonOptions" :key="x.name">
												<input type="checkbox" id="scales" name="scales">
												<label for="scales">{{ x.label }}</label>
											</div>
										</div>
									</div>
								</div>
								<div class="comments-area">
									<h4>Kommentare</h4>
									<div class="comments">
										<div v-if="comments.length > 0">
											<div class="comment" v-for="c in comments" :key="c.id">
												<div class="comment-value">{{ c.value }}</div>
												<div class="comment-creator">{{ c.creator }} - {{ c.created }}</div>
											</div>
										</div>
										<div class="no-comments" v-else>Es wurden noch keine Kommentare verfasst</div>
									</div>
									<div class="field" style="margin-top:35px" v-if="true">
										<FormulateInput
											type="textarea"
											v-model="comment"
											label="Kommentar hinzufügen"
											validation="required|max:50,length"
											validation-name="Kommentar"
											error-behavior="submit"
											:help="`Keep it under 1000 characters. ${1000 - comment.length} left.`"
										/>
										<div class="buttons">
											<transition mode="out-in" name="fade2">
												<div class="button-save disabled" v-if="!comment || (comment.length < 1)" key="disabledSaveButton">Kommentar hinzufügen</div>
												<div class="button-save" @click="createComment()" v-else-if="!saving" key="activeSaveButton">Kommentar hinzufügen</div>
												<div class="button-save saving" v-else key="savingSaveButton"><div class="button-loader"></div></div>
											</transition>
										</div>
									</div>
								</div>
								<div class="bottom-bar">
									<div class="button-back" @click="$router.go(-1)"><i class="fas fa-arrow-left"></i> Zurück</div>
									<div v-show="false">
										<transition mode="out-in" name="fade2">
											<div class="button-save disabled" v-if="!selectedReason || (selectedSubReasons.length < 1)" key="disabledSaveButton"><i class="far fa-save"></i> Speichern</div>
											<div class="button-save" @click="saveAction()" v-else-if="!saving" key="activeSaveButton"><i class="far fa-save"></i> Speichern</div>
											<div class="button-save saving" v-else key="savingSaveButton"><div class="button-loader"></div></div>
										</transition>
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainNavigation from './MainNavigation'
import Notification from './Notification'

export default {
	name: 'TrackingDetail',
	components: {
		MainNavigation,
		Notification
	},
	data() {
		return {
			details: null,
			reasons: null,
			selectedReason: null,
			selectedSubReason: '',
			loading: null,
			loadingDetails: true,
			loadingReasons: true,
			loadingComments: true,
			saving: false,
			notificationStatus: null,
			preLoaded: false,
			comment: '',
			comments: [],
			selectedSubReasons: [
			]
		}
	},
	props: [
		'sidebar',
		'user'
	],
	methods: {
		loadTrackingDetail() {
			this.loadingDetail = true
			this.$http.get('/api/v1/tracking/detail?id=' + this.$route.params.id,
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
			).then((response) => {
				this.details = response.data
				this.loadingDetail = false
			}).catch(() => {
				this.saving = false
				this.notificate('error')
			})
		},
		loadTrackingReasons() {
			this.loadingReasons = true
			this.$http.get('/api/v1/tracking/reasons?id=' + this.$route.params.id,
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
				}}
				).then((response) => {
				this.reasons = response.data['options']
				if (response.data['current']['reason'] != null) {
					this.selectedReason = response.data['current']['reason'].toString()
				}
				if (response.data['current']['subreasons'] != null) {
					const currentSr = response.data['current']['subreasons']
					for (let sr in currentSr) {
						this.selectedSubReasons.push(currentSr[sr])
					}
				}
				if (this.selectedReason) {
					this.preLoaded = true
				}
				this.loadingReasons = false
			}).catch(() => {
				this.saving = false
				this.notificate('error')
			})
		},
		saveAction() {
			this.saving = true
			this.$http.post('/api/v1/tracking/edit',
				{
					action: this.$route.params.id,
					reason: this.selectedReason,
					subreasons: this.selectedSubReasons
				},
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
			).then(() => {
				this.saving = false
				this.notificate('success')
			}).catch(() => {
				this.saving = false
				this.notificate('error')
			})
		},
		createComment() {
			this.saving = true
			this.$http.post('/api/v1/tracking/comments/create',
				{
					action: this.$route.params.id,
					comment: this.comment
				},
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
			).then(() => {
				this.saving = false
				this.comment = ''
				this.notificate('success')
				this.loadTrackingComments()
			}).catch(() => {
				this.saving = false
				this.notificate('error')
			})
		},
		loadTrackingComments() {
			this.loadingComments = true
			this.$http.get('/api/v1/tracking/comments/list?id=' + this.$route.params.id,
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
				}}
			).then((response) => {
				this.comments = response.data
				this.loadingComments = false
			}).catch(() => {
				this.loadingComments = false
				this.notificate('error')
			})
		},
		notificate(status) {
			this.notificationStatus = status
			setTimeout(function() {
				this.notificationStatus = null
			}.bind(this), 3000)
		},
		removeSelectedSubreason(option) {
			const index = this.selectedSubReasons.indexOf(option)
			this.selectedSubReasons.splice(index, 1)
			this.selectedSubReason = null
		}
	},
	computed: {
		sidebarWidth() {
			if (this.sidebar) { return 276 }
			return 0
		},
		reasonOptions() {
			let reasonOptionsDict = []
			for (let r in this.reasons) {
				reasonOptionsDict.push(
					{
						value: this.reasons[r].id.toString(),
						label: this.reasons[r].name
					}
				)
			}
			return reasonOptionsDict
		},
		subReasonOptions() {
			if (this.selectedReason != null) {
				let reasonOptionsDict = []

				for (let r in this.reasons) {
					if (this.reasons[r].id == this.selectedReason) {
						for (let sr in this.reasons[r].subreasons) {
							if (!this.selectedSubReasons.some(
								el => el.value == this.reasons[r].subreasons[sr].id
							)) {
								reasonOptionsDict.push(
									{
										value: this.reasons[r].subreasons[sr].id.toString(),
										label: this.reasons[r].subreasons[sr].name,
									}
								)
							}
						}
					}
				}
				return reasonOptionsDict
			}
			return false
		}
	},
	mounted() {
		this.loadTrackingDetail(),
		this.loadTrackingReasons(),
		this.loadTrackingComments()
	},
	watch: {
		'$route'() {
			this.loadTrackingDetail(),
			this.loadTrackingReasons(),
			this.loadTrackingReasons()
		},
		selectedReason() {
			if (this.loadingReasons == false) {
				if (!this.preLoaded) {
					if (this.selectedReason) {
						this.selectedSubReason = null
						this.selectedSubReasons = []
					}
				} else {
					this.preLoaded = false
				}
			}
		},
		selectedSubReason() {
			for (let e in this.subReasonOptions) {
				if (this.subReasonOptions[e].value == this.selectedSubReason) {
					this.selectedSubReasons.push({
						value: this.selectedSubReason,
						label: this.subReasonOptions[e].label
					})
					break
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
		overflow: auto;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
		text-transform: capitalize;
	}
	.subcontent {
		width: calc(50% - 15px);
		padding-top: 25px;
		margin-bottom: 25px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.full {
		width: auto;
		clear: both;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: auto;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		overflow: hidden;
	}
	.table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
		color: #1B2142;
	}
	.table-head {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: 12px;
		border-bottom: 2px solid #dfe9f0;
	}
	.table-head-column {
		padding-left: 20px;
		padding-right: 15px;
		box-sizing: border-box;
	}
	.table-row {
		width: auto;
		height: 50px;
		line-height: 50px;
		font-size: 13px;
		border-bottom: 1px solid #dfe9f0;
	}
	.table-row-column {
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 15px;
	}
	.summary-bar {
		width: auto;
		height: 60px;
	}
	.summary {
		width: auto;
		height: 50px;
		line-height: 50px;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 20px;
		float: left;
		border-radius: 4px;
		background-color: #FFFFFF;
		font-size: 13px;
		font-weight: 600;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		cursor: pointer;
	}
	.summary i {
		margin-right: 7px;
		color: rgb(218, 57, 8);
	}
	.summary.active {
		background-color: #1B2142;
		color: white;
	}
	.pagination-bar {
		width: auto;
		height: 54px;
		padding: 13px;
		box-sizing: border-box;
		font-size: 11px;
	}
	.pagination {
		width: auto;
		height: 28px;
		line-height: 28px;
		text-align: center;
		background-color: #FFFFFF;
        border: 1px solid #dfe9f0;
		display: inline-block;
		border-radius: 4px;
	}
	.pagination > div:hover {
		background-color: #f8f9fa;
		cursor: pointer;
	}
	.pagination-prev {
		width: 27px;
		height: 28px;
		float: left;
		box-sizing: border-box;
	}
	.pagination-pos {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.pagination-pos.active {
		background-color: #f8f9fa;
		color: #1B214270;
	}
	.pagination-next {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.loader {
		border: 3px solid transparent;
		border-top: 3px solid #1B2142;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 150px;
		margin-bottom: 150px;
	}
	.button-loader {
		border: 2px solid transparent;
		border-top: 2px solid #ffffff;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 11px;
		margin-bottom: 11px;
		margin-left: 20px;
		margin-right: 20px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	.fade2-enter-active, .fade2-leave-active {
		transition: opacity .5s;
	}
	.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
	.detail-title {
		font-size: 20px;
		font-weight: 700;
	}
	.detail-left .detail-subtitle {
		font-size: 16px;
		font-weight: 600;
		color: #1B214280;
		margin-top: 40px;
		margin-bottom: 15px;
	}
	.detail-right .detail-subtitle {
		font-size: 18px;
		font-weight: 600;
		color: #1B214280;
		margin-top: 10px;
		margin-bottom: 15px;
	}
	.detail-left {
		float: left;
		width: calc(50% - 50px);
		height: auto;
		margin-top: 25px;
	}
	.detail-right {
		float: right;
		width: calc(50% - 50px);
		height: auto;
		margin-top: 25px;
	}
	.detail-left .field {
		width: auto;
		height: 25px;
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #dfe9f0;
	}
	.detail-right .field {
		width: auto;
		height: auto;
		padding-top: 10px;
		padding-bottom: 15px;
		border-bottom: 1px solid #dfe9f0;
	}
	.detail-right .field .main-label {
		width: auto;
		height: 25px;
		line-height: 25px;
		font-size: 16px;
		font-weight: 600;
		float: left;
		margin-bottom: 10px;
		color: #1B214280;
	}
	.detail-right .field .sub-label {
		width: auto;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
		font-weight: 600;
		float: left;
		margin-bottom: 10px;
		color: #1B214280;
	}
	.detail-right .field select {
		width: 100% !important;
		display: inline-block !important;
		height: 35px;
		line-height: 35px;
		border: 1px solid #dfe9f0;
		border-radius: 4px;
		padding-left: 5px;
		padding-right: 5px;
		color: #1B2142;
	}
	.label {
		width: 50%;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
		font-weight: 600;
		float: left;
	}
	.value {
		width: 50%;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
		font-weight: 400;
		float: right;
		text-align: right;
	}
	.buttons {
		overflow: auto;
	}
	.bottom-bar {
		clear: both;
		width: auto;
		height: 40px;
		padding-top: 45px;
	}
	.button-back {
		display: inline-block;
		height: 36px;
		line-height: 36px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		border: 1px solid #dfe9f0;
		font-size: 13px;
		font-weight: 400;
		float: left;
		cursor: pointer;
	}
	.button-back i {
		margin-right: 6px;
		opacity: 0.5;
	}
	.button-save {
		display: inline-block;
		height: 36px;
		line-height: 36px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		background-color: #1B2142;
		color: white;
		font-size: 13px;
		font-weight: 600;
		float: right;
		cursor: pointer;
	}
	.button-save.disabled {
		cursor: default;
		background-color: #b3b8d4;
	}
	.button-save i {
		margin-right: 6px;
	}
	.button-save:not(.disabled):hover {
		background-color: #262c52;
	}
	.selected-2 {
		width: auto;
		height: auto;
		padding: 10px;
		margin-top: 10px;
		overflow: auto;
		background-color: #dfe9f050;
		border: 1px solid #dfe9f0;
		border-radius: 4px;
	}
	.selected-subreasons-label {
		font-size: 13px;
		font-weight: 600;
		margin-top: 15px;
	}
	.selected-subreason2 {
		float: left;
		height: 26px;
		line-height: 26px;
		background-color: #1B2142;
		border-radius: 4px;
		font-size: 12px;
		font-weight: 600;
		color: #FFFFFF;
		margin-right: 6px;
		overflow: auto;
	}
	.selected-subreason-value2 {
		float: left;
		padding-left: 10px;
		padding-right: 8px;
	}
	.selected-subreason-close2 {
		float: right;
		font-size: 10px;
		padding-left: 8px;
		padding-right: 9px;
		border-left: 1px solid #FFFFFF20;
		color: #b3b8d4;
	}
	.selected-subreasons {
		width: auto;
		height: auto;
		margin-top: 10px;
		overflow: auto;
		border-radius: 4px;
	}
	.selected-subreason {
		height: 34px;
		line-height: 34px;
		font-size: 13px;
		font-weight: 600;
		color: #1B2142;
		overflow: auto;
		margin-bottom: 6px;
		border-radius: 4px;
		background-color: #F8FBFD;
	}
	.selected-subreason-value {
		float: left;
		padding-left: 10px;
		padding-right: 8px;
	}
	.selected-subreason-close {
		float: right;
		font-size: 10px;
		padding-left: 16px;
		padding-right: 16px;
		color: #b3b8d4;
		cursor: pointer;
	}
	.selected-subreason-close:hover {
		color: #8b93bb;
	}
	.selection-field {
		width: auto;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 4px;
		border: 1px solid #dfe9f0;
		font-size: 13px;
	}
	.selection-dropdown {
		width: 450px;
		height: auto;
        position: absolute;
        margin-top: 0px;
        background-color: white;
        border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		border: 1px solid #dfe9f0;
		border-top: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
		padding-left: 8px;
		padding-right: 8px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
	}
	.selection-dropdown-item {
		width: auto;
		height: 30px;
		line-height: 30px;
		font-size: 13px;
		input[type=checkbox] {
			margin-top: 5px;
			margin-right: 10px;
		}
	}
	.comments-area {
		padding-top: 20px;
		margin-top: 20px;
	}
	.no-comments {
		font-size: 12px;
	}
	.comment {
		border: 1px solid #dfe9f0;
		border-radius: 5px;
		padding: 12px;
		margin-bottom: 10px;
	}
	.comment-value {
		font-size: 14px;
		padding-bottom: 5px;
	}
	.comment-creator {
		font-size: 11px;
		opacity: 0.75;
		text-align: right;
	}
</style>
