<template>
    <div>
        <div class="notification success" v-if="status == 'success'">
            <i class="fas fa-check"></i> Gespeichert
        </div>
        <div class="notification error" v-if="status == 'error'">
            <i class="fas fa-exclamation"></i> Ein Fehler ist aufgetreten
        </div>
        <div class="notification not-available" v-if="status == 'not-available'">
            <i class="fas fa-lock"></i> <b>Dieses Dashboard ist derzeit nicht freigeschaltet.</b> Bitte wenden Sie sich bei Interesse an unseren Support.
        </div>
    </div>
</template>

<script>
export default {
    name: 'Notification',
    props: [
        'status'
    ]
}
</script>

<style lang="scss" scoped>
    .notification {
        width: auto;
        height: auto;
        padding: 20px;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 10px;
    }
    .notification.success {
        background-color: rgb(144, 182, 83);
        color: white;
    }
    .notification.error {
        background-color: rgb(192, 69, 52);
        color: white;
    }
    .notification.not-available {
        background-color: #1B2142;
        color: rgb(255, 255, 255);
    }
    .notification.not-available i {
        color: #FF8A00;
    }
    .notification i {
        margin-right: 15px;
    }
</style>