<template>
	<div>
		<div class="button-area">
			<div v-show="!notAvailable && !error" class="export-button" @click="printButton"><i class="fas fa-print"></i> Drucken</div>
		</div>
		<div class="whitecontent" :style="{ height: height + 'px' }" v-if="error || notAvailable">
			<Notification v-if="error" status="error"></Notification>
			<Notification v-if="notAvailable" status="not-available"></Notification>
		</div>
		<div v-else class="whitecontent" :style="{ height: height + 'px' }" id="report-container">
		</div>
	</div>
</template>

<script>
import Notification from './Notification'
import * as pbi from 'powerbi-client'
export default {
	name: 'Embed',
	data() {
		return {
			error: false,
			notAvailable: false,
			report: null,
			printReady: false
		}
	},
	props: [
		'height',
		'name',
		'category'
	],
	components: {
		Notification
	},
	methods: {
		loadEmbeddedInfo() {
			this.$http.get('api/v1/embedded/info/' + this.category + "/"+ this.name,
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
			).then((response) => {
				this.loadDashboard(JSON.parse(response.data))
			}).catch((e) => {
				console.log(e)
				if (e.response.status == 404) {
					this.notAvailable = true
				} else {
					this.error = true
				}
			})
		},
		loadDashboard(embedData) {
			let powerbi = new pbi.service.Service(
				pbi.factories.hpmFactory,
				pbi.factories.wpmpFactory,
				pbi.factories.routerFactory
			)
			var reportContainer = document.getElementById('report-container')
			var reportLoadConfig = {
				type: 'report',
				tokenType: 1,
				id: embedData.reportConfig[0].reportId,
				permissions: pbi.models.Permissions.All,
				viewMode: pbi.models.ViewMode.View,
				settings: {
					filterPaneEnabled: false,
					navContentPaneEnabled: false
				},
				accessToken: embedData.accessToken,
				embedUrl: embedData.reportConfig[0].embedUrl
			}
			var report = powerbi.embed(reportContainer, reportLoadConfig)
			this.report = report
			report.off("loaded")
			report.on("loaded", function () {
				this.printReady = true
				console.log("Loaded")
			})
			report.off("rendered")
			report.on("rendered", function () {
				console.log("Rendered")
			})
			report.on("error", function (event) {
				console.log(event.detail)
				report.off("error")
				this.error = true
			})
			report.off("saved")
			report.on("saved", function (event) {
				console.log(event.detail)
				if (event.detail.saveAs) {
					console.log('In order to interact with the new report, create a new token and load the new report')
				}
			})
		},
		printButton() {
			this.report.print()
		}

	},
	mounted() {
		this.loadEmbeddedInfo()
	}
}
</script>

<style>
	iframe {
		border: 0px !important;
	}
</style>

<style scoped>
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: 680px;
		max-width: 1300px;
		padding: 40px;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
	}
	.button-area {
		width: auto;
		height: 35px;
		max-width: 1380px;
		margin-top: -35px;
	}
	.export-button {
		border: 1px solid #e1e3eb;
		color: #4b5172;
		padding: 7px 10px;
		border-radius: 4px;
		font-size: 12px;
		cursor: pointer;
		font-weight: 600;
		float: right;
	}
	.export-button:hover {
		background-color: white;
	}
	.export-button i {
		font-size: 12px;
		color: #9da3c5;
		margin-right: 5px;
		margin-left: 2px;
	}
</style>