import Vue from 'vue'
import App from './App.vue'
import router from './routes.js';
import axios from 'axios'

import VueFormulate from '@braid/vue-formulate'

Vue.config.productionTip = false

//axios.defaults.baseURL = 'https://bkapi-920348237057940932-dev.azurewebsites.net/'
//axios.defaults.baseURL = 'http://localhost:8080'
axios.defaults.baseURL = 'https://api.bekumoo.de'
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
//axios.defaults.headers.post['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
Vue.prototype.$http = axios
Vue.prototype.$roles = {
  ADMIN: 'Administrator',
  SALES_POTENTIAL: 'Vertriebs-Potential',
	A_B_TESTING: 'A_B_Testing',
	PARTS_S_MANAGER: 'Teiledienstleiter',
	WORKSHOP_MANAGER: 'Werkstattleiter',
	CEO: 'Geschäftsführer',
  SERVICE_MANAGER: 'Serviceleiter',
  SALES_SERVICE_COMBINED: 'Vertrieb_Service_combined',
	SALES_MANAGER: 'Vertriebsleiter'
}
	
Vue.use(VueFormulate)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')