<template>
	<div class="main">
		<div class="right" :style="{width: 'calc(100% - ' + sidebarWidth + 'px)'}">
			<MainNavigation :width="{width: 'calc(100% - ' + sidebarWidth + 'px)'}" :user="user"></MainNavigation>
			<div class="content content-padding">
				<div class="head">
					<router-link class="button-invite" tag="div" :to="{ name: 'settings-users-invite' }"><i class="fas fa-user-plus"></i> Nutzer hinzufügen</router-link>
					<div class="subtitle">Settings</div>
					<div class="title">Nutzerverwaltung</div>
				</div>
				<div class="subcontent full">
					<transition mode="out-in" name="fade2">
						<div class="whitecontent" v-if="loading" key="loading">
							<div class="loading"><div class="loader"></div></div>
						</div>
						<div class="whitecontent" v-else key="loaded">
							<table class="table">
								<tr class="table-head">
									<th class="table-head-column" v-for="col in cols" :key="col">{{ col }}</th>
								</tr>
								<router-link class="table-row" tag="tr" v-for="row in users" to="#" :key="row.id">
									<td class="table-row-column">{{ row.name }}</td>
									<td class="table-row-column">{{ row.role }}</td>
									<td class="table-row-column">{{ activeTransform(row.active) }}</td>
									<td class="table-row-column">{{ row.email }}</td>
									<td class="table-row-column">{{ row.date }}</td>
								</router-link>
							</table>
							<div class="pagination-bar">
								<div class="pagination">
									<div class="pagination-prev"><i class="fas fa-angle-left"></i></div>
									<div class="pagination-pos active">1</div>
									<div class="pagination-pos" v-show="false">2</div>
									<div class="pagination-pos" v-show="false">3</div>
									<div class="pagination-next"><i class="fas fa-angle-right"></i></div>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainNavigation from './MainNavigation'

export default {
	name: 'SettingsUsers',
	data() {
		return {
			cols: ['Name', 'Rolle', 'Aktiv', 'E-Mail', 'Anmeldedatum'],
			users: [],
			loading: true
		}
	},
	components: {
		MainNavigation
	},
	props: [
		'sidebar',
		'user'
	],
	methods: {
		loadUsers() {
			this.loading = true
			this.$http.get(
				'/api/v1/user/list',
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
				).then((response) => {
				this.users = response.data
				this.loading = false
			})
		},
		activeTransform(value) {
			if (value) {
				return 'Aktiv'
			} else {
				return 'Inaktiv'
			}
		}
	},
	computed: {
		sidebarWidth() {
			if (this.sidebar) {
				return 276
			}
			return 0
		}
	},
	mounted() {
		this.loadUsers()
		/*
		if (!this.user.admin) {
			this.$router.push({ name: 'dashboard' })
		}*/
	}
}
</script>

<style scoped>
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
	}
	.subcontent {
		width: calc(50% - 15px);
		margin-top: 50px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.full {
		width: auto;
		clear: both;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: auto;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		overflow:hidden;
	}
	.table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
		color: #1B2142;
	}
	.table-head {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: 12px;
		border-bottom: 2px solid #dfe9f0;
	}
	.table-head-column {
		padding-left: 20px;
		padding-right: 15px;
		box-sizing: border-box;
	}
	.table-row {
		width: auto;
		height: 50px;
		line-height: 50px;
		font-size: 13px;
		border-bottom: 1px solid #dfe9f0;
		cursor: pointer;
	}
	.table-row:hover {
		background-color: #dfe9f030;
	}
	.table-row-column {
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 15px;
	}
	.summary-bar {
		width: auto;
		height: 60px;
	}
	.summary {
		width: auto;
		height: 50px;
		line-height: 50px;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 20px;
		float: left;
		border-radius: 4px;
		background-color: #FFFFFF;
		font-size: 13px;
		font-weight: 600;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		cursor: pointer;
	}
	.summary i {
		margin-right: 7px;
		color: rgb(218, 57, 8);
	}
	.summary.active {
		background-color: #1B2142;
		color: white;
	}
	.pagination-bar {
		width: auto;
		height: 54px;
		padding: 13px;
		box-sizing: border-box;
		font-size: 11px;
	}
	.pagination {
		width: auto;
		height: 28px;
		line-height: 28px;
		text-align: center;
		background-color: #FFFFFF;
        border: 1px solid #dfe9f0;
		display: inline-block;
		border-radius: 4px;
	}
	.pagination > div:hover {
		background-color: #f8f9fa;
		cursor: pointer;
	}
	.pagination-prev {
		width: 27px;
		height: 28px;
		float: left;
		box-sizing: border-box;
	}
	.pagination-pos {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.pagination-pos.active {
		background-color: #f8f9fa;
		color: #1B214270;
	}
	.pagination-next {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.loader {
		border: 3px solid transparent; /* Light grey */
		border-top: 3px solid #1B2142; /* Blue */
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 150px;
		margin-bottom: 150px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	.fade2-enter-active, .fade2-leave-active {
		transition: opacity .5s;
	}
	.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
	.button-invite {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		margin-top: 20px;
		background-color: #1B2142;
		color: white;
		font-size: 13px;
		font-weight: 600;
		float: right;
		cursor: pointer;
	}
	.button-invite:hover {
		background-color: #262c52;
	}
	.button-invite i {
		margin-right: 6px;
	}
</style>
