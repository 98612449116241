
<template>
	<div class="tiles">
		<div class="tile" v-for="x in 4" :key="x">
			<div class="tile-subtitle">Sales</div>
			<div class="tile-title">Neuwagen</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Tile',
	mounted() {
	}
}
</script>

<style scoped lang="scss">
	.tiles {
		margin-top: 50px;
		display: flex;
		flex-direction: row;
	}
	.tile {
		flex-grow: 1;
		height: 154px;
		float: left;
		background-color: #1B2142;
		border-radius: 4px;
		color: #ffffff;
		box-sizing: border-box;
		padding: 30px;
		font-size: 18px;
		margin-right: 20px;
	}
	.tile:last-child {
		margin-right: 0;
	}
	.tile-subtitle {
		height: 20px;
		line-height: 20px;
		margin-top: 50px;
	}
	.tile-title {
		height: 30px;
		line-height: 30px;
		font-weight: 700;
	}
</style>
