<template>
	<div class="main">
		<div class="right" :style="{width: 'calc(100% - ' + sidebarWidth + 'px)'}">
			<MainNavigation :width="{width: 'calc(100% - ' + sidebarWidth + 'px)'}" :user="user"></MainNavigation>
			<div class="content content-padding">
				<div class="head">
					<div class="subtitle">Settings</div>
					<div class="title">Nutzer hinzufügen</div>
				</div>
				<div class="subcontent full">
					<transition mode="out-in" name="fade2">
						<Notification v-if="notificationStatus == 'success'" status="success"></Notification>
						<Notification v-if="notificationStatus == 'error'" status="error"></Notification>
					</transition>
					<transition mode="out-in" name="fade2">
						<div class="whitecontent" v-if="loading" key="loading">
							<div class="loading"><div class="loader"></div></div>
						</div>
						<div class="whitecontent" v-else key="loaded">
							<FormulateForm name="form">
								<div class="form-area">
									<FormulateInput class="form-field" type="text" v-model="firstName" label="Vorname" validation="bail|required|max:100|min:2" @validation="validation.firstName = $event" />
									<FormulateInput class="form-field" type="text" v-model="lastName" label="Nachname" validation="bail|required|max:100|min:2" @validation="validation.lastName = $event" />
								</div>
								<FormulateInput class="formulate-input-no-margin" type="email" v-model="email" label="E-Mail" validation="email" @validation="validation.email = $event" />
								<div class="info-box"><i class="fas fa-info-circle"></i> Der Empfänger erhält eine E-Mail um ein Passwort zu vergeben</div>
								<FormulateInput
									v-model="department"
									:options="availableDepartments"
									type="select"
									placeholder="Betrieb auswählen"
									label="Betrieb"
									validation="required|max:100|min:1"
									@validation="validation.department = $event"
								/>
								<FormulateInput
									v-model="role"
									:options="availableRoles"
									type="select"
									placeholder="Nutzerrolle auswählen"
									label="Nutzerrolle"
									validation="required|max:100|min:1"
									@validation="validation.role = $event"
								/>
							</FormulateForm>
							<div class="bottom-bar">
								<div class="button-back" @click="$router.go(-1)"><i class="fas fa-arrow-left"></i> Zurück</div>
								<transition mode="out-in" name="fade2">
									<div class="button-save disabled" v-if="!validateForm" key="disabledSaveButton"><i class="far fa-save"></i> Speichern</div>
									<div class="button-save" @click="saveUser()" v-else-if="!saving" key="activeSaveButton"><i class="far fa-save"></i> Speichern</div>
									<div class="button-save saving" v-else key="savingSaveButton"><div class="button-loader"></div></div>
								</transition>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MainNavigation from './MainNavigation'
import Notification from './Notification'

export default {
	name: 'SettingsUserInvite',
	data() {
		return {
			loading: true,
			saving: false,
			notificationStatus: null,
			firstName: '',
			lastName: '',
			email: '',
			department: '',
			role: '',
			availableDepartments: [
			],
			availableRoles: [
				{ value: 'Serviceleiter', label: 'Serviceleiter' },
				{ value: 'Geschäftsführer', label: 'Geschäftsführer' }
			],
			validation: {
				firstName: {},
				lastName: {},
				email: {},
				role: {},
				department: {}
			}
		}
	},
	components: {
		MainNavigation,
		Notification
	},
	props: [
		'sidebar',
		'user'
	],
	methods: {
		buttonClick() {
			for (var key in this.validation) {
				var value = this.validation[key];
				if (value['hasErrors']) {
					return false
				}
			}
			return true
		},
		saveUser() {
			this.saving = true
			this.$http.post('/api/v1/user/invite',
				{
					firstname: this.firstName,
					lastname: this.lastName,
					email: this.email,
					department: this.department,
					role: this.role
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('jwt')
					}
				}
			).then(() => {
				this.$formulate.reset('form')
				this.saving = false
				this.notificate('success')
			}).catch(() => {
				this.saving = false
				this.notificate('error')
			})
		},
		loadData() {
			this.loading = true
			this.$http.get(
				'/api/v1/data/invite',
                { headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }}
				).then((response) => {
				let departments = response.data
				for (let d in departments) {
					this.availableDepartments.push({
						value: departments[d],
						label: departments[d]
					})
				}
				this.loading = false
			})
		},
		notificate(status) {
			this.notificationStatus = status
			setTimeout(function() {
				this.notificationStatus = null
			}.bind(this), 3000)
		}
	},
	computed: {
		sidebarWidth() {
			if (this.sidebar) {
				return 276
			}
			return 0
		},
		validateForm() {
			for (var key in this.validation) {
				var value = this.validation[key];
				if (value['hasErrors']) {
					return false
				}
			}
			return true
		}
	},
	mounted() {
		/*
		if (!this.user.admin) {
			this.$router.push({ name: 'dashboard' })
		}
		*/
		this.loadData()
	}
}
</script>

<style scoped lang="scss">
	.formulate-input-no-margin {
		margin-bottom: 0px;
	}
	.form-area {
		display: flex;
		flex-direction: row;
	}
	.form-field {
		flex-grow: 1;
		margin-right: 30px;
	}
	.form-field:last-child {
		margin-right: 0px;
	}
	.info-box {
		color: rgb(133, 172, 216);
		border-radius: 4px;
		font-size: 12px;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		font-weight: 500;
		i {
			margin-right: 3px;
		}
	}
	.right {
		float: right;
		transition: all 0.5s;
	}
	.content {
		box-sizing: border-box;
		padding: 45px;
	}
	.subtitle {
		font-size: 16px;
		color: #FF8A00;
		margin-bottom: 5px;
	}
	.title {
		font-size: 30px;
		font-weight: 700;
	}
	.subcontent {
		width: calc(50% - 15px);
		margin-top: 50px;
	}
	.subcontent .subtitle {
		color: #1B2142;
		opacity: 0.5;
	}
	.content-left {
		float: left;
		width: calc(100% - 300px);
	}
	.content-right {
		float: right;
		width: 300px;
	}
	.full {
		width: auto;
		clear: both;
	}
	.whitecontent {
		margin-top: 25px;
		width: auto;
		height: auto;
		border-radius: 4px;
		background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		overflow:hidden;
		padding: 40px;
	}
	.table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
		color: #1B2142;
	}
	.table-head {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: 12px;
		border-bottom: 2px solid #dfe9f0;
	}
	.table-head-column {
		padding-left: 20px;
		padding-right: 15px;
		box-sizing: border-box;
	}
	.table-row {
		width: auto;
		height: 50px;
		line-height: 50px;
		font-size: 13px;
		border-bottom: 1px solid #dfe9f0;
		cursor: pointer;
	}
	.table-row:hover {
		background-color: #dfe9f030;
	}
	.table-row-column {
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 15px;
	}
	.summary-bar {
		width: auto;
		height: 60px;
	}
	.summary {
		width: auto;
		height: 50px;
		line-height: 50px;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 20px;
		float: left;
		border-radius: 4px;
		background-color: #FFFFFF;
		font-size: 13px;
		font-weight: 600;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
		cursor: pointer;
	}
	.summary i {
		margin-right: 7px;
		color: rgb(218, 57, 8);
	}
	.summary.active {
		background-color: #1B2142;
		color: white;
	}
	.pagination-bar {
		width: auto;
		height: 54px;
		padding: 13px;
		box-sizing: border-box;
		font-size: 11px;
	}
	.pagination {
		width: auto;
		height: 28px;
		line-height: 28px;
		text-align: center;
		background-color: #FFFFFF;
        border: 1px solid #dfe9f0;
		display: inline-block;
		border-radius: 4px;
	}
	.pagination > div:hover {
		background-color: #f8f9fa;
		cursor: pointer;
	}
	.pagination-prev {
		width: 27px;
		height: 28px;
		float: left;
		box-sizing: border-box;
	}
	.pagination-pos {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.pagination-pos.active {
		background-color: #f8f9fa;
		color: #1B214270;
	}
	.pagination-next {
		width: 28px;
		height: 28px;
		float: left;
		border-left: 1px solid #dfe9f0;
		box-sizing: border-box;
	}
	.loader {
		border: 3px solid transparent; /* Light grey */
		border-top: 3px solid #1B2142; /* Blue */
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 150px;
		margin-bottom: 150px;
	}
	.button-loader {
		border: 2px solid transparent;
		border-top: 2px solid #ffffff;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		animation: spin 1s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 11px;
		margin-bottom: 11px;
		margin-left: 20px;
		margin-right: 20px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	.fade2-enter-active, .fade2-leave-active {
		transition: opacity .5s;
	}
	.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
	.button-invite {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		margin-top: 20px;
		background-color: #1B2142;
		color: white;
		font-size: 13px;
		font-weight: 600;
		float: right;
		cursor: pointer;
	}
	.button-invite:hover {
		background-color: #262c52;
	}
	.button-invite i {
		margin-right: 6px;
	}
	.buttons {
		overflow: auto;
	}
	.bottom-bar {
		clear: both;
		width: auto;
		height: 40px;
		padding-top: 45px;
	}
	.button-back {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		border: 1px solid #dfe9f0;
		font-size: 14px;
		font-weight: 400;
		float: left;
		cursor: pointer;
	}
	.button-back i {
		margin-right: 6px;
		opacity: 0.5;
	}
	.button-save {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 4px;
		background-color: #1B2142;
		color: white;
		font-size: 14px;
		font-weight: 600;
		float: right;
		cursor: pointer;
	}
	.button-save.disabled {
		cursor: default;
		background-color: #b3b8d4;
	}
	.button-save i {
		margin-right: 6px;
	}
	.button-save:hover {
		background-color: #262c52;
	}
</style>
