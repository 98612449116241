<template>
    <div class="main" :style="width">
        <transition mode="out-in" name="user-fade">
            <div v-if="user">
                <div class="user">
                    <div class="user-name" @click="dropdown = !dropdown">{{ user.name }} <i class="fas fa-caret-down"></i></div>
                    <transition mode="out-in" name="dropdown-fade">
                        <div class="user-dropdown" v-show="dropdown">
                            <ul>
                                <li><router-link tag="a" :to="{ name: 'settings-profile' }">Profil</router-link></li>
                                <li v-if="user.admin"><router-link tag="a" :to="{ name: 'settings-users' }">Nutzerverwaltung</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'logout' }">Ausloggen</router-link></li>
                            </ul>
                        </div>
                    </transition>
                </div>

                <a class="support-button" @click="getToken()">
                    <i class="far fa-life-ring"></i> Support
                </a>
                <ul class="nav">
                    <li v-if="user.role == this.$roles.SALES_MANAGER || user.role == this.$roles.SALES_SERVICE_COMBINED"><router-link :active="isActive('Vertrieb')" active-class="active" tag="a" :to="{ name: 'vertrieb' }">Vertrieb</router-link></li>
                    <li v-if="user.role != this.$roles.SALES_MANAGER"><router-link :active="isActive('Tracking')" active-class="active" tag="a" :to="{ name: 'tracking' }">Tracking</router-link></li>
                    <li v-if="false"><router-link :active="isActive('Sales')" active-class="active" tag="a" :to="{ name: 'sales' }">Sales</router-link></li>
                    <li v-if="user.role != this.$roles.SALES_MANAGER"><router-link :active="isActive('Service')" active-class="active" tag="a" :to="{ name: 'service' }">Service</router-link></li>
                    <li v-if="false"><router-link :active="isActive('Dashboard')" active-class="active" tag="a" :to="{ name: 'dashboard' }">Start</router-link></li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'MainNavigation',
    data() {
        return {
            dropdown: false
        }
    },
    props: [
        'width',
        'user'
    ],
    methods: {
        isActive(routeName) {
            if (this.$route.meta.title === routeName) return true
            return false
        },
        async getToken() {
       
            axios.get(`api/v1/zendesk/get_jwt`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
            })
            .then((response) => {
                // Redirect to the Zendesk SSO page with the received token
                
                window.open(
                    response.data,
                    '_blank' // <- This is what makes it open in a new window.
                );
            })
            .catch((error) => {
                console.log(error);
            });
 
        },
    }
}
</script>

<style scoped lang="scss">
    .main {
        height: 56px;
        line-height: 56px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        padding-left: 40px;
        padding-right: 40px;
        background-color: white;
        position: fixed;
        width: auto;
        box-sizing: border-box;
    }
    ul.nav {
        list-style: none;
        margin: 0;
        padding: 0;
        float: right;
    }
    .nav li {
        float: right;
        margin-left: 10px;
        margin-right: 10px;
    }
    .nav a {
        height: 56px;
        line-height: 56px;
        display: block;
        text-decoration: none;
        padding-left: 7px;
        padding-right: 7px;
        color: #1B2142;
        font-weight: 400;
        font-size: 14px;
    }
    .nav a.active {
        height: 54px;
        border-bottom: 2px solid #FF8A00;
        font-weight: 700;
    }
    .support-button {
        height: 32px;
        line-height: 32px;
        margin-top: 12px;
        float: right;
        background-color: #FF8A00;
        border-radius: 4px;
        padding-left: 14px;
        padding-right: 14px;
        color: white;
        font-size: 13px;
        font-weight: 600;
        margin-left: 25px;
        cursor: pointer;
        text-decoration: none;
    }
    .support-button i {
        margin-right: 5px;
    }
    .user {
        height: 56px;
        line-height: 56px;
        float: right;
        border-radius: 4px;
        padding-left: 14px;
        padding-right: 14px;
        font-size: 12px;
        font-weight: 400;
        margin-left: 20px;
    }
    .user-name {
        width: auto;
        height: 56px;
        cursor:pointer;
        i {
            margin-left: 2px;
            opacity: 0.35;
            font-size: 12px;
            margin-top: 5px;
        }
    }
    .user-dropdown {
        width: 150px;
        height: auto;
        position: absolute;
        right: 40px;
        margin-top: -5px;
        background-color: white;
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        -moz-box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(7, 37, 66, 0.2);
        padding-top: 5px;
        padding-bottom: 5px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-align: left;
        }
        li {
            height: 36px;
            width: auto;
            line-height: 36px;
            padding-left: 18px;
        }
        a {
            height: 36px;
            width: auto;
            display: block;
            text-decoration: none;
            color: #1B2142;
        }
        li:hover {
            background-color: #F8FBFD;
        }
    }
    .user-fade-enter-active, .user-fade-leave-active {
        transition: opacity .5s;
    }
    .user-fade-enter, .user-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .dropdown-fade-enter-active, .dropdown-fade-leave-active {
        transition: opacity .1s;
    }
    .dropdown-fade-enter, .dropdown-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>